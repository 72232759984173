import { html } from "lit-html"

const inactiveCls =
  "border-b-2 border-white bg-white text-gray-interactive hover:text-black"
const activeCls = "border-b-2 border-yellow bg-[#fffef5] text-black"

const Toggle = ({ defaultLabel, altLabel, altSort, onToggle }) => html`
  ${defaultLabel && altLabel
    ? html`
        <div data-section="time-toggle" class="mb-6 flex items-center">
          <button
            class="flex items-center justify-center px-6 py-5 ${altSort
              ? inactiveCls
              : activeCls}"
            type="button"
            aria-pressed="${!altSort}"
            @click=${() => (altSort ? onToggle(false) : null)}
          >
            ${defaultLabel}
          </button>
          <button
            class="flex items-center justify-center px-6 py-5 ${altSort
              ? activeCls
              : inactiveCls}"
            aria-pressed="${altSort}"
            type="button"
            @click=${() => (altSort ? null : onToggle(true))}
          >
            ${altLabel}
          </button>
        </div>
      `
    : ``}
`

export default Toggle
