import { config, dom, library } from "@fortawesome/fontawesome-svg-core"

import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

import { cbArrowUpFromSquare } from "./cbArrowUpFromSquare"
import { cbBluesky } from "./cbBlueSky"
import { cbOverflow } from "./cbOverflow"
import { cbPlus } from "./cbPlus"
import { cbSearch } from "./cbSearch"
import { cbShareNodes } from "./cbShareNodes"
import { farCalendarDay } from "./farCalendarDay"
import { farCircleMinus } from "./farCircleMinus"
import { farLinkSimple } from "./farLinkSimple"
import { fasArrowDownToLine } from "./fasArrowDownToLine"

// Fix FontAwesome icon flashing
// https://medium.com/@fabianterh/fixing-flashing-huge-font-awesome-icons-on-a-gatsby-static-site-787e1cfb3a18
config.autoAddCss = false

export const fontAwesomeIcons = [
  ...Object.values(fab),
  ...Object.values(far),
  ...Object.values(fas),
  cbArrowUpFromSquare,
  cbBluesky,
  cbOverflow,
  cbPlus,
  cbSearch,
  cbShareNodes,
  farCalendarDay,
  farCircleMinus,
  farLinkSimple,
  fasArrowDownToLine,
]

export function setupFontawesomeIcons() {
  library.add(...fontAwesomeIcons)
  dom.watch()
}
