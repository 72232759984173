import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useEffect, useState, useRef } from "haunted"
import { ref } from "../directives"

const populateInputLabel = (selected, selectOptions, labelPrefix) => {
  const selectedLength = selected.length
  if (!selectedLength || selectedLength === selectOptions.length) {
    return `${labelPrefix}: All`
  } else {
    const labels = selected
      .map((item) => item.label)
      .reverse()
      .join(", ")
    return `${labelPrefix}: ${labels}`
  }
}

const addOptions = (selected, addedOptions) => {
  const selectedValues = selected.map(({ value }) => value)
  const newSelectedOptions = addedOptions.filter(
    ({ value }) => !selectedValues.includes(value)
  )
  return [...selected, ...newSelectedOptions]
}

const removeOptions = (selected, removedOptions) => {
  const unselectedValues = removedOptions.map(({ value }) => value)
  return selected.filter(({ value }) => !unselectedValues.includes(value))
}

export const FilterAutocompleteSelect = virtual(
  ({ name, required, placeholder, labelPrefix, options }) => {
    const containerRef = useRef(null)
    const [didMount, setDidMount] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const [selected, setSelected] = useState(
      options.filter(({ selected: optionSelected }) => optionSelected)
    )
    const [selectOptions, setSelectOptions] = useState(options)
    const [inputValue, setInputValue] = useState(``)

    const visibleOptions = selectOptions.filter(({ label }) =>
      label.toLowerCase().includes(inputValue.toLowerCase())
    )
    const inputLabel = populateInputLabel(selected, selectOptions, labelPrefix)

    // Set didMount to control which effects run on initial render
    useEffect(() => {
      setDidMount(true)
    }, [])

    useEffect(() => {
      const handleDocClickFocus = (event) => {
        const refContainsEvent =
          containerRef.current && containerRef.current.contains(event.target)

        if (!refContainsEvent && expanded) {
          setExpanded(false)
        } else if (refContainsEvent && !expanded) {
          setExpanded(true)
        }
      }

      document.addEventListener("focusin", handleDocClickFocus, false)
      document.addEventListener("click", handleDocClickFocus, false)

      return () => {
        document.removeEventListener("focusin", handleDocClickFocus)
        document.removeEventListener("click", handleDocClickFocus)
      }
    }, [expanded, containerRef])

    useEffect(() => {
      if (!didMount) return
      if (!expanded && selected.length === 0 && inputValue !== ``) {
        setInputValue(``)
      }
    }, [expanded])

    useEffect(() => {
      if (!didMount) return
      if (focusedIndex >= 0 && containerRef.current) {
        containerRef.current
          .querySelector(`li:nth-child(${focusedIndex + 1})`)
          .focus()
      } else if (containerRef.current) {
        containerRef.current.querySelector("input[role='combobox']").focus()
      }
    }, [focusedIndex])

    useEffect(() => {
      if (!didMount) return
      setFocusedIndex(-1)
    }, [inputValue])

    useEffect(() => {
      const selectedValue = selected.map(({ value }) => value)
      const updatedSelectOptions = selectOptions.map((option) => ({
        ...option,
        selected: selectedValue.includes(option.value),
      }))
      setSelectOptions(updatedSelectOptions)
    }, [selected])

    // Implement select-like keyboard controls
    const onKeydown = (e) => {
      if (["ArrowDown", "ArrowRight"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex < visibleOptions.length - 1) {
          setFocusedIndex(focusedIndex + 1)
        }
      } else if (["ArrowUp", "ArrowLeft"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex > -1) {
          setFocusedIndex(focusedIndex - 1)
        }
      } else if (e.code === "Enter") {
        e.preventDefault()
        if (focusedIndex >= 0) {
          toggleOption(visibleOptions[focusedIndex])
        }
      } else if (e.code === "Space") {
        if (focusedIndex >= 0) {
          e.preventDefault()
          toggleOption(visibleOptions[focusedIndex])
        }
      }
    }

    const updateSelected = (selectedOptions) => {
      setSelected(selectedOptions)
      const $form = containerRef.current.closest("form")
      $form
        .querySelector(`input[id="id_${name}"]`)
        .dispatchEvent(new Event("input"))
    }

    const toggleOption = (option) => {
      let selectedOptions = selected
      if (!selected.some(({ value }) => value === option.value)) {
        selectedOptions = addOptions(selected, [option])
      } else {
        selectedOptions = removeOptions(selected, [option])
      }
      updateSelected(selectedOptions)
    }

    const selectAll = () => {
      const selectedOptions = addOptions(selected, visibleOptions)
      updateSelected(selectedOptions)
    }

    const unselectAll = () => {
      const selectedOptions = removeOptions(selected, visibleOptions)
      updateSelected(selectedOptions)
    }

    return html`
      <div
        class="filter-autocomplete-field"
        @keydown=${onKeydown}
        ?ref=${ref(containerRef)}
      >
        <input
          type="text"
          class="input"
          aria-expanded=${expanded.toString()}
          aria-owns="${name}__listbox"
          aria-autocomplete="list"
          autocomplete="off"
          placeholder=${placeholder || `Select ${labelPrefix}`}
          aria-label=${inputLabel}
          id="id_${name}"
          role="combobox"
          ?required=${required}
          value=${inputLabel}
          readonly
        />
        <ul
          role="listbox"
          id="${name}__listbox"
          class="p-5 scroll-y-no-scrollbar ${expanded ? `block` : `hidden`}"
        >
          <div class="search-input relative mb-5">
            <input
              type="text"
              class="input"
              placeholder="Search"
              .value=${inputValue}
              @input=${(e) => {
                setInputValue(e.target.value || ``)
              }}
            />
            ${inputValue
              ? html`<button
                  type="button"
                  class="delete absolute top-4half right-4"
                  @keydown=${(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault()
                      setInputValue(``)
                    }
                  }}
                  @click=${() => {
                    setInputValue(``)
                  }}
                ></button>`
              : ``}
          </div>
          <div
            class="option-list max-h-300 overflow-y-auto overflow-scroll-touch"
          >
            ${visibleOptions.length === 0
              ? html`<li class="m-0 p-4">No results found</li>`
              : ``}
            ${repeat(
              selectOptions,
              ({ value }) => value,
              ({ label, value, selected }, index) => html`
                <li
                  aria-selected=${(index === focusedIndex).toString()}
                  class="m-0 px-2 py-4 flex items-center cursor-pointer ${!visibleOptions.some(
                    (opt) => opt.value === value
                  )
                    ? "hidden"
                    : ""}"
                  tabindex="-1"
                  role="option"
                  @click=${(e) => {
                    e.target.querySelector("input")?.click()
                  }}
                >
                  <input
                    type="checkbox"
                    name=${name}
                    value=${value}
                    .checked=${selected}
                    id="id_${name}_${index}"
                    @change=${() => {
                      toggleOption({ label, value })
                    }}
                  />
                  <label for="id_${name}_${index}" class="ml-5">
                    ${label}
                  </label>
                </li>
              `
            )}
          </div>
          <div class="mt-4 flex justify-end font-bold">
            <a href="javascript:void(0)" class="mr-8" @click=${selectAll}>
              Select all
            </a>
            <a href="javascript:void(0)" @click=${unselectAll}> Clear </a>
          </div>
        </ul>
      </div>
    `
  }
)

export default function renderFilterAutocomplete(el) {
  const options = [...el.querySelectorAll("option")]
    .map((option) => ({
      label: option.innerText.trim(),
      value: option.value,
      selected: option.hasAttribute("selected"),
    }))
    .filter(({ value }) => !!value.trim())
  const mount = document.createElement("div")
  render(
    FilterAutocompleteSelect({
      options,
      name: el.name,
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
      labelPrefix: el.getAttribute("data-label-prefix") || ``,
      required: el.hasAttribute("required"),
    }),
    mount
  )
  el.replaceWith(...mount.childNodes)
}
