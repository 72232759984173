export const prefix = "far"
export const iconName = "calendar-day"
export const width = 16
export const height = 18
export const ligatures = []
export const unicode = "f783"
export const svgPathData =
  "M13.8333 2.49967H13V0.833008H11.3333V2.49967H4.66667V0.833008H3V2.49967H2.16667C1.24167 2.49967 0.508333 3.24967 0.508333 4.16634L0.5 15.833C0.5 16.7497 1.24167 17.4997 2.16667 17.4997H13.8333C14.75 17.4997 15.5 16.7497 15.5 15.833V4.16634C15.5 3.24967 14.75 2.49967 13.8333 2.49967ZM13.8333 15.833H2.16667V6.66634H13.8333V15.833ZM3.83333 8.33301H8V12.4997H3.83333V8.33301Z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const farCalendarDay = definition
