export const prefix = "far"
export const iconName = "link-simple"
export const width = 18
export const height = 10
export const ligatures = []
export const unicode = "e1cd"
export const svgPathData =
  "M2.24984 4.99967C2.24984 3.57467 3.40817 2.41634 4.83317 2.41634H8.1665V0.833008H4.83317C2.53317 0.833008 0.666504 2.69967 0.666504 4.99967C0.666504 7.29967 2.53317 9.16634 4.83317 9.16634H8.1665V7.58301H4.83317C3.40817 7.58301 2.24984 6.42467 2.24984 4.99967ZM5.6665 5.83301H12.3332V4.16634H5.6665V5.83301ZM13.1665 0.833008H9.83317V2.41634H13.1665C14.5915 2.41634 15.7498 3.57467 15.7498 4.99967C15.7498 6.42467 14.5915 7.58301 13.1665 7.58301H9.83317V9.16634H13.1665C15.4665 9.16634 17.3332 7.29967 17.3332 4.99967C17.3332 2.69967 15.4665 0.833008 13.1665 0.833008Z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const farLinkSimple = definition
